<template>
  <div id="login">
    <b-container fluid>
      <b-row>
        <b-col></b-col>
        <b-col><h1 style="color: white; margin-bottom: 1em">Login</h1></b-col>
        <b-col></b-col>
      </b-row>
      <b-row>
        <b-col></b-col>
        <b-col class="col-sm-4" style="color: white">
          <b-form-group>
            <b-form-input id="username" type="text" v-model="username" placeholder="username"></b-form-input>
          </b-form-group>
          <b-form-group>
            <b-form-input id="password" type="password" v-model="password" placeholder="password"></b-form-input>
          </b-form-group>
          <b-button @click.prevent="login()" type="submit" variant="success">Login</b-button>
        </b-col>
        <b-col></b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  data () {
    return {
      username: '',
      password: '',
      device_name: ''
    }
  },

  methods: {
    login () {
      this.$store
          .dispatch('login', {
            username: this.username,
            password: this.password,
            device_name: this.getDeviceName()
          })
          .then(() => {
            this.$router.push({ name: 'CmsDashboard' })
          })
          .catch(err => {
            console.log(err)
          })
    },
    getDeviceName() {
      const ua = navigator.userAgent;
      if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
        return "tablet";
      }
      if (
          /Mobile|iP(hone|od|ad)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
              ua
          )
      ) {
        return "mobile";
      }
      return "desktop";
    }
  }
}
</script>

<style scoped>
  @media only screen and (max-device-width: 568px) {
    #login {
      margin-bottom: 11em;
    }
  }

  @media only screen and (min-device-width: 569px) and (orientation: portrait) {
    #login {
      margin-bottom: 18em;
    }
  }

  @media only screen and (min-device-width: 569px) and (orientation: landscape) {
    #login {
      margin-bottom: 17em;
    }
  }
</style>